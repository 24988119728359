import { v4 as uuid } from 'uuid';

import { Injectable } from '@angular/core';

import { injectSupabase } from '../functions/inject-supabase.function';

@Injectable({
  providedIn: 'root'
})
export class AvatarApi {
  public supabase = injectSupabase();

  async insert(file: File, filePath?: string) {
    if (filePath) this.delete(filePath);
    filePath = this.generatePath(file);

    await this.supabase.storage.from('avatars').upload(filePath, file);
    return filePath;
  }

  update(file: File, path: string) {
    return this.supabase.storage.from('avatars').update(path, file);
  }

  download(path: string) {
    return this.supabase.storage.from('avatars').download(path);
  }

  delete(path: string) {
    return this.supabase.storage.from('avatars').remove([path]);
  }

  private generatePath(file: File) {
    const fileExt = file.name.split('.').pop();
    return `${uuid()}.${fileExt}`;
  }
}
